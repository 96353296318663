import React, { useState, useEffect } from "react";
import { Button, Pagination, Modal } from "antd";

const OrderMListBody = ({ ordersList }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]); // Track expanded rows
  const pageSize = 10;

  const handleInvoice = (url) => {
    setInvoiceUrl(url);
    setInvoiceModal(true);
  };

  const toggleRowExpand = (orderId) => {
    setExpandedRows((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  const getBackgroundColor = (status) => {
    switch (status) {
      case "delivered":
        return "bg-teal-500";
      case "inProgress":
        return "bg-yellow-400";
      case "return":
        return "bg-red-500";
      case "damaged":
        return "bg-red-500";
      default:
        return "bg-gray-200";
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [ordersList]);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = ordersList?.slice(startIndex, endIndex);

  return (
    <div className="w-full bg-white">
      <table className="w-full border-b border-gray-300">
        <thead className="min-w-full">
          <tr className="border-b">
            <th className="border-b p-4 text-center">S.No</th>
            <th className="border-b p-4 text-center">Order ID</th>
            <th className="border-b p-4 text-center">Customer</th>
            <th className="border-b p-4 text-center">Price</th>
            <th className="border-b p-4 text-center">Products</th>
            <th className="border-b p-4 text-center">Ordertype</th>
            <th className="border-b p-4 text-center">Status</th>
            <th className="border-b p-4 text-center">Invoice</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((order, index) => {
            const deliveryTypes = order.InfoData.deliveryType;
            const hasFair = deliveryTypes.includes("Fair");
            const hasInstant = deliveryTypes.includes("Instant");
            const fairStatus = order.InfoData.nextFairStatus;
            const instantStatus = order.InfoData.nextInstantStatus;

            return (
              <React.Fragment key={order.orderId}>
                <tr>
                  <td className="border-b p-4">{startIndex + index + 1}</td>
                  <td className="border-b p-4">
                    {hasFair && hasInstant ? (
                      <Button
                        type="link"
                        onClick={() => toggleRowExpand(order.orderId)}
                      >
                        {order.orderId}
                      </Button>
                    ) : (
                      <span>{order.orderId}</span>
                    )}
                  </td>
                  <td className="border-b p-4">{order.customerName}</td>
                  <td className="border-b p-4">{order.totalPrice}</td>
                  <td className="border-b p-4">{order.totalQuantity}</td>
                  <td className="border-b p-4">
                    {/* Display Fair type if both exist, otherwise display the single available type */}
                    <span>{hasFair ? "Fair" : hasInstant ? "Instant" : ""}</span>
                  </td>
                  <td className="border-b p-4">
                    <span
                      className={`border-b px-4 py-2 rounded-md text-white ${getBackgroundColor(
                        hasFair ? fairStatus : instantStatus
                      )}`}
                    >
                      {hasFair ? fairStatus : instantStatus}
                    </span>
                  </td>
                  <td className="border-b p-4">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleInvoice(
                          order.invoice.invoiceURLInstant ||
                            order.invoice.invoiceURLFair ||
                            ""
                        );
                      }}
                      className="text-[#009A00] border-none"
                    >
                      View
                    </Button>
                  </td>
                </tr>
                {/* Expanded row for additional details showing Instant type */}
                {expandedRows.includes(order.orderId) && hasFair && hasInstant && (
                  <tr>
                    <td colSpan="8" className="border-b p-4">
                      <table className="w-full border">
                        <thead>
                          <tr className="bg-gray-100">
                            <th className="border p-2 text-left">Price</th>
                            <th className="border p-2 text-left">Products</th>
                            <th className="border p-2 text-left">Ordertype</th>
                            <th className="border p-2 text-left">Status</th>
                            <th className="border p-2 text-left">Invoice</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border p-2">{order.totalPrice}</td>
                            <td className="border p-2">{order.totalQuantity}</td>
                            <td className="border p-2">Instant</td>
                            <td className="border p-2">
                              <span
                                className={`border-b px-4 py-2 rounded-md text-white ${getBackgroundColor(
                                  instantStatus
                                )}`}
                              >
                                {instantStatus}
                              </span>
                            </td>
                            <td className="border p-2">
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleInvoice(
                                    order.invoice.invoiceURLInstant ||
                                      order.invoice.invoiceURLFair ||
                                      ""
                                  );
                                }}
                                className="text-[#009A00] border-none"
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>

      <div className="flex justify-end mt-2">
        {ordersList.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={ordersList.length}
            onChange={handleChangePage}
          />
        )}
      </div>

      <Modal
        visible={invoiceModal}
        onCancel={() => setInvoiceModal(false)}
        footer={null}
        centered
      >
        <iframe src={invoiceUrl} width="100%" height="550px" />
        <button
          onClick={() => {
            setInvoiceModal(false);
            setInvoiceUrl("");
          }}
          className="mt-2 bg-red-500 text-white py-1 px-3 rounded"
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

export default OrderMListBody;
