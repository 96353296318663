// const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// // Function to make a GET request
// export const getApiData = async (endpoint, useAuth) => {
//   const token = localStorage.getItem("userToken");
//   const headers = {
//     "Content-Type": "application/json",
//     "Access-Control-Allow-Headers": "Content-Type",
//     Authorization: `Bearer ${token}`,
//   };
//   if (useAuth) {
//     headers["Authorization"] = `Bearer ${token}`;
//   }
//   const url = `${BASE_URL}/${endpoint}`;
//   try {
//     const response = await fetch(url, {
//       method: "GET",
//       headers: headers,
//     });

//     if (response.ok) {
//       // Status code is in the range 200-299
//       const data = await response.json();
//       console.log(data);
//       return { data: data, loading: false };
//     } else {
//       // Handle non-success status code (e.g., 400 Bad Request)
//       const errorData = await response.json();
//       console.error("Error fetching data:", errorData);
//       throw errorData;
//     }
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     throw error;
//   }
// };

// // Function to make a POST request
// export const postApiData = async (endpoint, data) => {
//   const url = `${BASE_URL}/${endpoint}`;
//   const token = localStorage.getItem("userToken");

//   try {
//     const response = await fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Headers": "Content-Type",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });

//     if (response.ok) {
//       const responseData = await response.json();
//       return { data: responseData, loading: false };
//     } else {
//       const errorData = await response.json();
//       console.error("Error posting data:", errorData);
//       throw { error: errorData, loading: false };
//     }
//   } catch (error) {
//     console.error("Error posting data:", error);
//     throw { error, loading: false };
//   }
// };
// // Function to make a POST request using Authorization
// export const postAuthApiData = async (endpoint, data) => {
//   const url = `${BASE_URL}/${endpoint}`;
//   const token = localStorage.getItem("userToken");

//   try {
//     const response = await fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Headers": "Content-Type",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });

//     if (response.ok) {
//       const responseData = await response.json();
//       return { data: responseData, loading: false };
//     } else {
//       const errorData = await response.json();
//       console.error("Error posting data:", errorData);
//       throw { error: errorData, loading: false };
//     }
//   } catch (error) {
//     console.error("Error posting data:", error);
//     throw { error, loading: false };
//   }
// };

// // Function to make a PUT request
// export const putAuthApiData = async (endpoint, data) => {
//   const url = `${BASE_URL}/${endpoint}`;
//   const token = localStorage.getItem("userToken");

//   try {
//     const response = await fetch(url, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Headers": "Content-Type",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });

//     if (response.ok) {
//       const responseData = await response.json();
//       return { data: responseData, loading: false };
//     } else {
//       const errorData = await response.json();
//       console.error("Error posting data:", errorData);
//       throw { error: errorData, loading: false };
//     }
//   } catch (error) {
//     console.error("Error posting data:", error);
//     throw { error, loading: false };
//   }
// };

// // Function to make a DELETE request
// export const deleteApiData = async (endpoint, data) => {
//   const url = `${BASE_URL}/${endpoint}`;
//   const token = localStorage.getItem("userToken");

//   try {
//     const response = await fetch(url, {
//       method: "DELETE",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Headers": "Content-Type",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });

//     if (response.ok) {
//       console.log(response);
//       // Status code is in the range 200-299
//       const responseData = await response.json();
//       console.log(responseData);
//       return { data: responseData };
//     } else {
//       // Handle non-success status code (e.g., 400 Bad Request)
//       const errorData = await response.json();
//       console.error("Error deleting data:", errorData);
//       throw errorData;
//     }
//   } catch (error) {
//     console.error("Error deleting data:", error);
//     throw error;
//   }
// };

import React from "react";
import { useSelector } from "react-redux";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ApiDomain = () => {
  const token = useSelector((state) => state.token.token);
  console.log(token);

  const getApiData = async (endpoint, useAuth) => {
    const token = localStorage.getItem("userToken");
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
      Authorization: `Bearer ${token}`,
    };
    if (useAuth) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `${BASE_URL}/${endpoint}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        // Status code is in the range 200-299
        const data = await response.json();
        console.log(data);
        return { data: data, loading: false };
      } else {
        // Handle non-success status code (e.g., 400 Bad Request)
        const errorData = await response.json();
        console.error("Error fetching data:", errorData);
        throw errorData;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const postApiData = async (endpoint, data) => {
    const url = `${BASE_URL}/${endpoint}`;
    const token = localStorage.getItem("userToken");

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseData = await response.json();
        return { data: responseData, loading: false };
      } else {
        const errorData = await response.json();
        console.error("Error posting data:", errorData);
        throw { error: errorData, loading: false };
      }
    } catch (error) {
      console.error("Error posting data:", error);
      throw { error, loading: false };
    }
  };
  // Function to make a POST request using Authorization
  const postAuthApiData = async (endpoint, data) => {
    const url = `${BASE_URL}/${endpoint}`;
    const token = localStorage.getItem("userToken");

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseData = await response.json();
        return { data: responseData, loading: false };
      } else {
        const errorData = await response.json();
        console.error("Error posting data:", errorData);
        throw { error: errorData, loading: false };
      }
    } catch (error) {
      console.error("Error posting data:", error);
      throw { error, loading: false };
    }
  };
  const putAuthApiData = async (endpoint, data) => {
    const url = `${BASE_URL}/${endpoint}`;
    const token = localStorage.getItem("userToken");

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseData = await response.json();
        return { data: responseData, loading: false };
      } else {
        const errorData = await response.json();
        console.error("Error posting data:", errorData);
        throw { error: errorData, loading: false };
      }
    } catch (error) {
      console.error("Error posting data:", error);
      throw { error, loading: false };
    }
  };

  // Function to make a DELETE request
  const deleteApiData = async (endpoint, data) => {
    const url = `${BASE_URL}/${endpoint}`;
    const token = localStorage.getItem("userToken");

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log(response);
        // Status code is in the range 200-299
        const responseData = await response.json();
        console.log(responseData);
        return { data: responseData };
      } else {
        // Handle non-success status code (e.g., 400 Bad Request)
        const errorData = await response.json();
        console.error("Error deleting data:", errorData);
        throw errorData;
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      throw error;
    }
  };
  return {
    getApiData,
    postApiData,
    postAuthApiData,
    putAuthApiData,
    deleteApiData,
  };
};

export default ApiDomain;
