import React from "react";
// import { useSelector } from "react-redux";
import { Dropdown, Avatar, Menu } from "antd"; // Import Menu
import { Link } from "react-router-dom"; // Import Link
import { CgProfile } from "react-icons/cg"; // Import CgProfile for the Profile icon
import { useSelector, useDispatch } from "react-redux";
import useLogout from "../../util/authUtils";

const Header = () => {
  const logout = useLogout();
  const profileDetails = useSelector((state) => state.userProfile);
  const profileData = useSelector((state) => state.profileData);
  const selectedPuja = useSelector((state) => state.selectedPujas);
  console.log(selectedPuja);

  const items = [
    {
      label: (
        <Link to="/Profile" className="block text-gray-800 hover:text-blue-500 transition-all duration-200">
          <Menu.Item key="Profile" icon={<CgProfile className="inline-block mr-2 text-lg" />}>
            <span className="font-medium text-lg">Profile</span>
          </Menu.Item>
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <button
          className="logout-btn text-white bg-red-500 hover:bg-red-600 transition-all duration-200 rounded px-6 py-2 w-full mt-4 font-semibold shadow-lg"
          onClick={logout}
        >
          Log out
        </button>
      ),
      key: "2",
    },
  ];
  
  return (
    <header className="w-full shadow z-10">
      <div className="px-4 py-4 w-full">
        <div className="flex justify-between items-center">
          <div className="name">
            <h6 className="text-lg font-semibold text-gray-800">
              {selectedPuja}
            </h6>
          </div>

          {/* Avatar with profile image or fallback to initials */}
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div className="cursor-pointer">
              {profileDetails?.Image ? (
                <Avatar src={profileDetails.Image} size={50} />
              ) : (
                <Avatar
                  shape="circle"
                  size={40}
                  style={{
                    backgroundColor: "#6750A4",
                    fontWeight: 600,
                    fontSize: 17,
                  }}
                >
                  {profileDetails?.name
                    ? profileDetails.name[0].toUpperCase()
                    : "A"}
                </Avatar>
              )}
            </div>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default Header;
