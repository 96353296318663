import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import EmployeeCard from "./EmployeeCard";
import EmployeeList from "./EmployeeList";
import { axiosInstance } from "../../util/axiosInstance";

const EmployeeMain = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState("All");
  const [rolesData, setRolesData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [reportsDate, setReportsDate] = useState([]);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetchAdminRolesData();
    fetchEmployeeData();
  }, []);

  // const fetchAdminRolesData = async () => {
  //   const rolesEndpoint = `${baseURL}/listOfRoles`;
  //   try {
  //     setLoading(true);
  //     const token = localStorage.getItem("Mr_Sasta");
  //     if (!token) {
  //       message.error("Authentication token is missing");
  //       setLoading(false);
  //       return;
  //     }

  //     const response = await fetch(rolesEndpoint, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     const data = await response.json();
  //     if (response.ok && data.responseCode === 200) {
  //       setRolesData(data?.data);
  //     } else {
  //       message.error(data.message);
  //     }
  //   } catch (error) {
  //     message.error("Failed to fetch roles data.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const fetchEmployeeData = async (dateStrings = null) => {
  //   setLoading(true);
  //   try {
  //     const token = localStorage.getItem("Mr_Sasta");
  //     if (!token) {
  //       message.error("Authentication token is missing");
  //       setLoading(false);
  //       return;
  //     }

  //     const response = await fetch(`${baseURL}/listOfEmployes`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         startDate: dateStrings ? dateStrings[0] : null,
  //         endDate: dateStrings ? dateStrings[1] : null,
  //       }),
  //     });

  //     const responseData = await response.json();

  //     if (response.status === 200) {
  //       // Success case
  //       const { data } = responseData;
  //       setEmployeeData(data);

  //       const activeEmployee = data.filter(
  //         (employee) => employee.status === "active"
  //       );
  //       const inactiveEmployee = data.filter(
  //         (employee) => employee.status === "inactive"
  //       );

  //       setTotalCount(data.length);
  //       setActiveCount(activeEmployee.length);
  //       setInactiveCount(inactiveEmployee.length);
  //     } else if (response.status === 400) {
  //       message.error(responseData.message);
  //     } else {
  //       message.error(responseData.message);
  //     }
  //   } catch (error) {
  //     message.error("Something went wrong.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchAdminRolesData = async () => {
    const rolesEndpoint = "/listOfRoles";
    try {
      setLoading(true);
      const response = await axiosInstance.get(rolesEndpoint);

      const data = response.data;
      console.log(response);
      if (response.status === 200 && data.responseCode === 200) {
        setRolesData(data?.data);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error("Failed to fetch roles data.");
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployeeData = async (dateStrings = null) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("Mr_Sasta");
      // if (!token) {
      //   message.error("Authentication token is missing");
      //   setLoading(false);
      //   return;
      // }
      const response = await axiosInstance.post("/listOfEmployes", {
        startDate: dateStrings ? dateStrings[0] : "",
        endDate: dateStrings ? dateStrings[1] : "",
      });

      const responseData = response.data;
      console.log(response);

      if (response.status === 200) {
        const { data } = responseData;
        setEmployeeData(data);

        const activeEmployee = data.filter(
          (employee) => employee.status === "active"
        );
        const inactiveEmployee = data.filter(
          (employee) => employee.status === "inactive"
        );

        setTotalCount(data?.length);
        setActiveCount(activeEmployee?.length);
        setInactiveCount(inactiveEmployee?.length);
      } else {
        message.error(responseData?.message);
      }
    } catch (error) {
      message.error(error.response?.data?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const filterEmployeesBySearchInput = (employees, searchInput) => {
    if (!searchInput) return employees;
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return employees.filter((employee) => {
      const lowerCaseAddress = String(employee.address).toLowerCase();
      return (
        employee.username.toLowerCase().includes(lowerCaseSearchInput) ||
        employee.email.toLowerCase().includes(lowerCaseSearchInput) ||
        String(employee.phoneNumber).includes(searchInput) ||
        lowerCaseAddress.includes(lowerCaseSearchInput) ||
        employee.roleName.toLowerCase().includes(lowerCaseSearchInput)
      );
    });
  };

  const filteredEmployee = filterEmployeesBySearchInput(
    employeeData.filter((Employee) => {
      if (filter === "All") return true;
      if (filter === "Active") return Employee.status === "active";
      if (filter === "Inactive") return Employee.status === "inactive";
      return false;
    }),
    searchInput
  );

  return (
    <Spin spinning={loading}>
      <div>
        <EmployeeCard
          employeeData={employeeData}
          fetchEmployeeData={fetchEmployeeData}
          rolesData={rolesData}
          filter={filter}
          setFilter={setFilter}
          activeCount={activeCount}
          inactiveCount={inactiveCount}
          totalCount={totalCount}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setReportsDate={setReportsDate}
          reportsDate={reportsDate}
        />
        <EmployeeList
          employeeData={filteredEmployee}
          loading={loading}
          error={error}
          fetchEmployeeData={fetchEmployeeData}
          rolesData={rolesData}
        />
      </div>
    </Spin>
  );
};

export default EmployeeMain;
