import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Spin, message } from "antd";
import { Button, FormControl, InputLabel, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateToken, updatingUserProfile } from "../../redux/action";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import ApiDomain from "../../api/ApiDomain";
import useLogout from "../../util/authUtils";

const Login = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = useLogout();
  const { getApiData, postApiData, putAuthApiData, deleteApiData } =
    ApiDomain();

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const onFinish = async (values) => {
    const endpoint = "adminLogin";
    const apiUrl = `${baseURL}/${endpoint}`;
    setLoading(true);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();
      const token = data?.user?.token ? data?.user?.token : null;
      if (data.responseCode === 200) {
        dispatch(updateToken(token));
        localStorage.setItem("Mr_Sasta", token);
        // message.success("Logged in successfully");
        fetchUserProfile();
        // navigate("/home");
      } else if (data.responseCode === 400) {
        message.error(data.message);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserProfile = async () => {
    const Endpoint = "getUserProfile";
    try {
      setLoading(true);
      const { data, loading: postLoading } = await getApiData(Endpoint, true);
      console.log("User Details fetched successful:", data);
      setLoading(postLoading);
      if (data.responseCode === 200) {
        console.log(data.message);
        const loginTime = new Date();
        const date = loginTime.getDate().toString().padStart(2, "0");
        const month = months[loginTime.getMonth()];
        const year = loginTime.getFullYear();
        const hours = loginTime.getHours().toString().padStart(2, "0");
        const minutes = loginTime.getMinutes().toString().padStart(2, "0");
        const ampm = hours >= 12 ? "pm" : "am";
        const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
        const formattedLoginTime = `${date},${month} ${year}-${formattedHours}:${minutes} ${ampm}`;
        console.log(formattedLoginTime);
        const userData = {
          ...data?.user,
          formattedLoginTime: formattedLoginTime,
        };
        dispatch(updatingUserProfile(userData));
      } else if (data.responseCode === 400) {
        console.log(data);
        console.log(data.message);
      } else if (data.responseCode === 401) {
        message.error(data?.message);
        logout();
      }
    } catch (error) {
      // console.log("User Details fetching failed:", error);
      // message.error(error);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Spin spinning={loading}>
      <div className='min-h-screen flex items-center justify-center h-full'>
        <div className='bg-white rounded-lg shadow-2xl w-fit max-w-7xl flex h-[75vh]'>
          <div className='w-1/2 hidden lg:block'>
            <img
              src='/Assests/Images/Login-image.png'
              alt='Login visual'
              className='h-full w-full object-cover rounded-l-md'
            />
          </div>

          <div className='w-96 p-4 flex flex-col items-center justify-center'>
            <h2 className='text-2xl font-bold text-center mb-2 text-[#009A00]'>
              Welcome to Mr.Sasta
            </h2>
            <p className='text-center mb-4'>Log in with your credentials</p>
            <Form
              form={form}
              name='login'
              onFinish={onFinish}
              className='space-y-4 !w-80 gap-5'>
              <div className='mb-6'>
                <Form.Item
                  name='email'
                  className='mt-3 text-left gap-4'
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email address",
                    },
                  ]}>
                  <TextField
                    fullWidth
                    label='Email Address*'
                    variant='outlined'
                    size='small'
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        fontSize: "14px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "0.875rem",
                      },
                    }}
                  />
                </Form.Item>
              </div>
              <div className='!mt-4 !mb-6'>
                <Form.Item
                  name='password'
                  className='mt-3 text-left'
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password",
                    },
                  ]}>
                  <TextField
                    fullWidth
                    label='Password*'
                    type={showPassword ? "text" : "password"} // Toggle between 'text' and 'password'
                    variant='outlined'
                    size='small'
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        fontSize: "14px",
                      },

                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label={
                              showPassword ? "Hide password" : "Show password"
                            }
                            onClick={handleTogglePasswordVisibility}
                            edge='end'>
                            {showPassword ? (
                              <MdOutlineVisibilityOff
                                style={{ fontSize: "16px" }}
                              />
                            ) : (
                              <MdOutlineVisibility
                                style={{ fontSize: "16px" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "0.875rem",
                      },
                    }}
                  />
                  {/* <FormControl variant='outlined'>
                    <InputLabel htmlFor='outlined-adornment-password'>
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-password'
                      size='medium'
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                            edge='end'>
                            {showPassword ? (
                              <MdOutlineVisibility />
                            ) : (
                              <MdOutlineVisibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label='Password'
                    />
                  </FormControl> */}
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  type='submit'
                  variant='contained'
                  fullWidth
                  sx={{
                    backgroundColor: "#009A00",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "green",
                    },
                  }}>
                  Login
                </Button>
              </Form.Item>
            </Form>
            <p className='!text-end mt-4 !justify-end flex'>
              <a
                onClick={() => navigate("/forgotpassword")}
                className='text-[#009A00] hover:text-[#009A00]'>
                Forgot password?
              </a>
            </p>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Login;
