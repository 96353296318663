import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { axiosInstance } from "../../util/axiosInstance";
import Addcategory from "./Addcategory";
import CategoryList from "./categorylist";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const endpoint = "getAlladminCategories";
const apiUrl = `${baseURL}/${endpoint}`;

const CategoryMain = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateCategory, setUpdateCategory] = useState(null);
  const token = localStorage.getItem("Mr_Sasta");

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;

      if (data.responseCode === 200) {
        setCategories(data.categories);
      } else if (data.responseCode === 400) {
        setError(data.message);
      } else {
        setError("An unexpected error occurred.");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [token]);

  return (
    <Spin spinning={loading}>
      <div className="flex bg-white m-0">
        <div className="w-1/3">
          <Addcategory
            categories={categories}
            setCategories={setCategories}
            fetchCategories={fetchCategories}
            setLoading={setLoading}
            setError={setError}
            updateCategory={updateCategory}
            setUpdateCategory={setUpdateCategory}
          />
        </div>
        <div className="w-[2px] h-[500px] bg-gray-300 mx-4"></div>
        <div className="w-2/3">
          <CategoryList
            categories={categories}
            setCategories={setCategories}
            setLoading={setLoading}
            setError={setError}
            setUpdateCategory={setUpdateCategory}
          />
        </div>
      </div>
    </Spin>
  );
};

export default CategoryMain;
