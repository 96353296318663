import React, { useEffect, useState } from "react";
import { BsCardChecklist } from "react-icons/bs";
import { FaBoxArchive } from "react-icons/fa6";
import { MdDeliveryDining } from "react-icons/md";
import { MdMoney } from "react-icons/md";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const OrderManagementCards = () => {
  const [dispatchedCount, setDispatchedCount] = useState(0);
  const [deliveredCount, setDeliveredCount] = useState(0);
  const [returnsCount, setReturnsCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [confirmedCount, setConfirmedCount] = useState(0);

  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");

  useEffect(() => {
    const fetchOrderStatusCounts = async () => {
      try {
        const response = await fetch(`${baseURL}/OrdersCount`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const result = await response.json();

        if (result.responseCode === 200) {
          const {
            totalOrders,
            confirmed,
            dispatched,
            delivered,
            return: returnCount,
          } = result.data;
          setTotalCount(totalOrders);
          setConfirmedCount(confirmed);
          setDispatchedCount(dispatched);
          setDeliveredCount(delivered);
          setReturnsCount(returnCount);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrderStatusCounts();
  }, [baseURL, token]);

  return (
    <div className="flex justify-between items-center p-4">
      <div className="flex flex-wrap gap-5 w-full">
        <div className="bg-white rounded-md shadow-xl w-1/5 h-24 min-w-[140px] text-center">
          <div className="flex justify-between items-center p-2 mb-2">
            <span className="text-sm font-semibold text-gray-800">
              Total Orders
            </span>
            <BsCardChecklist className="text-lg text-[#009A00]" />
          </div>
          <div className="w-4/5 mx-auto text-2xl font-bold text-left">
            {totalCount}
          </div>
        </div>

        {/* <div className="bg-white rounded-md shadow-xl w-1/5 h-24 min-w-[140px] text-center">
          <div className="flex justify-between items-center p-2 mb-2">
            <span className="text-sm font-semibold text-gray-800">
              Confirmed
            </span>
            <BsCardChecklist className="text-lg text-[#009A00]" />
          </div>
          <div className="w-4/5 mx-auto text-2xl font-bold text-left">
            {confirmedCount}
          </div>
        </div> */}

        <div className="bg-white rounded-md shadow-xl w-1/5 h-24 min-w-[140px] text-center">
          <div className="flex justify-between items-center p-2 mb-2">
            <span className="text-sm font-semibold text-gray-800">
              Dispatched
            </span>
            <FaBoxArchive className="text-lg text-[#009A00]" />
          </div>
          <div className="w-4/5 mx-auto text-2xl font-bold text-left">
            {dispatchedCount}
          </div>
        </div>

        <div className="bg-white rounded-md shadow-xl w-1/5 h-24 min-w-[140px] text-center">
          <div className="flex justify-between items-center p-2 mb-2">
            <span className="text-sm font-semibold text-gray-800">
              Delivered
            </span>
            <MdDeliveryDining className="text-lg text-[#009A00]" />
          </div>
          <div className="w-4/5 mx-auto text-2xl font-bold text-left">
            {deliveredCount}
          </div>
        </div>

        <div className="bg-white rounded-md shadow-xl w-1/5 h-24 min-w-[140px] text-center">
          <div className="flex justify-between items-center p-2 mb-2">
            <span className="text-sm font-semibold text-gray-800">Returns</span>
            <MdMoney className="text-lg text-[#009A00]" />
          </div>
          <div className="w-4/5 mx-auto text-2xl font-bold text-left">
            {returnsCount}
          </div>
        </div>
      </div>

      <div className="self-start">
        <RangePicker className="w-full" format="YYYY-MM-DD" />
      </div>
    </div>
  );
};

export default OrderManagementCards;
