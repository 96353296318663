import { Form, Input, Select, Spin, Modal, message } from "antd";
import React, { useEffect, useState } from "react";

const AddRider = ({ close ,riders , Rider }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [riderImage, setRiderImage] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
    setRiderImage("");
  }, [close]);

  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");

  const handleAadharImage = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg"];

    if (!allowedTypes.includes(file.type)) {
      alert("Only PNG and JPG images are allowed.");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      alert("Image is greater than 5MB. The maximum limit is 5MB.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setRiderImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/addRider`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          riderName: values.username,
          email: values.email,
          phone: values.phoneNumber,
          drivingLicense: values.drivingLicense,
          aadhaarNumber: values.aadhaarNumber,
          address: values.address,
          profilePic: riderImage,
        }),
      });

      const result = await response.json();

      if (response.status === 200) {
        message.success(result.message);
        setSuccessModal(true);
        riders(); 
        Rider();
        form.resetFields();
        
      } else if (response.status === 400) {
        message.error(result.message);
      }
    } catch (err) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="AddRider-container">
        <div className="AddRider-content">
          <Form
            form={form}
            name="add_rider"
            className="edit-profile-form"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              label="Rider Name"
               className="mt-0 mb-4"
              rules={[{ required: true, message: "Please enter rider name" }]}
            >
              <Input type="text" placeholder="Enter Rider Name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              className="mt-0 mb-4"
              rules={[
                { required: true, message: "Please enter email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input type="email" placeholder="Enter Email" />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
               className="mt-0 mb-4"
              rules={[
                { required: true, message: "Please enter Phone number!" },
              ]}
            >
              <Input type="text" placeholder="Enter Phone number" />
            </Form.Item>
            <Form.Item
              name="drivingLicense"
              label="driving License"
               className="mt-0 mb-4"
              rules={[
                { required: true, message: "Please enter driving License" },
              ]}
            >
              <Input type="text" placeholder="Enter driving License number" />
            </Form.Item>
            <Form.Item
              name="aadhaarNumber"
              label="aadhaar Number"
               className="mt-0 mb-4"
              rules={[
                { required: true, message: "Please enter aadhaar Number" },
              ]}
            >
              <Input type="text" placeholder="Enter aadhaar Number" />
            </Form.Item>
            <Form.Item
              name="address"
              label="address"
               className="mt-0 mb-4"
              rules={[
                { required: false, message: "Please enter address" },
              ]}
            >
              <Input type="text" placeholder="Enter aaddress" />
            </Form.Item>

            <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4">
              {riderImage ? (
                <div className="FileChange">
                  <div className="image-section">
                    <img src={riderImage} alt="Rider" />
                  </div>
                  <label htmlFor="fileInput" className="primary-text pointer">
                    Change Image
                  </label>
                  <div className="document-attach">
                    <input
                      type="file"
                      id="fileInput"
                      onChange={handleAadharImage}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              ) : (
                <div className="FileUpload ">
                  <div className="flex justify-center">
                    <img
                      src="/Assests/Images/image-gallery.png"
                      alt="Rider"
                      className="file-upload"
                    />
                  </div>
                  <div>
                    <p>
                      Add Profile image or{" "}
                      <label
                        htmlFor="fileInput"
                        className="text-[#009A00] font-medium cursor-pointer"
                      >
                        browse
                      </label>
                      <div className="document-attach">
                        <input
                          type="file"
                          id="fileInput"
                          onChange={handleAadharImage}
                          style={{ display: "none" }}
                        />
                      </div>
                    </p>
                    <small className="secondary-text">Maximum size: 5MB</small>
                  </div>
                </div>
              )}
            </div>

            <Form.Item>
              <div className="flex justify-between">
                <div
                  className="add-Rider-cancel-button text-[#009A00] border border-[#009A00] rounded-md p-4 py-2"
                  onClick={close}
                >
                  <p>Cancel</p>
                </div>
                <button
                  htmlType="submit"
                  className="add-Rider-submit-button !bg-[#009A00] !hover:bg-[#009A00] text-white py-2 rounded-md font-medium p-4"
                >
                  <p>Submit</p>
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <Modal
          visible={successModal}
          onCancel={() => setSuccessModal(false)}
          centered
          footer={null}
          className="custom-modal"
        >
          <div className="Rider-success-container">
            <div className="Rider-success-content">
              <div className="flex justify-center">
                <img src="Assests/Images/success_image.png" alt="" />
              </div>
              <h5 className="font-medium justify-center">
                Rider Added <br /> Successfully
              </h5>
            </div>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};
export default AddRider;
