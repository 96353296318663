import { Avatar, Switch, Pagination, message, Modal } from "antd";
import React, { useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { axiosInstance } from "../../util/axiosInstance";
import SkuContant from "./SkuContant";

const ProductList = ({ products, setproduct }) => {
  const [loading, setLoading] = useState(false);
  const [showSkuModal, setshowSkuModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const toggleStatus = async (product) => {
    const Endpoint = "toggleAdminProductStatus";
    const status = product?.status;

    try {
      setLoading(true);
      const requestData = {
        productId: product?._id,
      };
      const response = await axiosInstance.put(
        `${baseURL}/${Endpoint}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Mr_Sasta")}`,
          },
        }
      );
      if (response.data.responseCode === 200) {
        message.success(response.data.message);

        const updatedProducts = products.map((p) => {
          if (p._id === product._id) {
            return {
              ...p,
              status: status === "active" ? "inactive" : "active",
            };
          }
          return p;
        });

        setproduct(updatedProducts);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to toggle status");
    } finally {
      setLoading(false);
    }
  };
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  const close = () => {
    setshowSkuModal(false);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = products.slice(startIndex, endIndex);

  return (
    <div className="p-1 bg-white !overflow-auto">
      <div className="Header-section flex justify-between mb-4">
        <p className="text-sm font-bold">Product List</p>
      </div>
      <table className="min-w-full">
        <thead className="bg-white">
          <tr>
            <th className="border-b p-2">S.No</th>
            <th className="border-b p-2 flex justify-center">Product Name</th>
            <th className="border-b p-2">Description</th>
            <th className="border-b p-2">SKU</th>
            <th className="border-b p-2">Stock</th>
            <th className="border-b p-2">Status</th>
            <th className="border-b p-2">Edit</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item, index) => (
            <tr key={item.productId}>
              <td className="border-b p-2 items-center">
                {startIndex + index + 1}
              </td>
              <td className="border-b p-2 flex items-center justify-start">
                {item.productImage ? (
                  <Avatar
                    size={35}
                    shape="circle"
                    src={item.productImage}
                    className="mr-2"
                  />
                ) : (
                  <Avatar
                    size={35}
                    shape="circle"
                    className="mr-2 bg-gray-200 text-black capitalize"
                  >
                    {item.productName.charAt(0).toUpperCase()}
                  </Avatar>
                )}
                {item.productName}
              </td>
              <td className="border-b p-2">{item.description}</td>
              <td
                className="border-b p-2 text-blue-600 cursor-pointer "
                onClick={() => setshowSkuModal(true)}
              >
                {item.skus.length}
              </td>
              <td className="border-b p-2 cursor-pointer">
                {item.skus && item.skus.length > 0 ? (
                  <>
                    {item.skus[0].quantityValue || 0}{" "}
                    {item.skus[0].unit || "--"}
                  </>
                ) : (
                  "--"
                )}
              </td>
              <td className="border-b p-2">
                <Switch
                  checked={item?.status === "active"}
                  onChange={() => toggleStatus(item)}
                  loading={loading}
                  style={{
                    backgroundColor:
                      item?.status === "active" ? "#009A00" : "#d9d9d9",
                  }}
                />
              </td>
              <td className="border-b text-center items-center">
                <div className="flex justify-center">
                  <MdOutlineEdit
                    className="text-[#009A00] cursor-pointer"
                    size={20}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {products.length === 0 && (
        <div className="no-data-message mt-3">
          <p>No data found</p>
        </div>
      )}
      <div className="Pagination-container mt-3 justify-end flex">
        {products.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={products.length}
            onChange={handleChangePage}
          />
        )}
      </div>
      <Modal
        visible={showSkuModal}
        centered
        onCancel={() => setshowSkuModal(false)}
        title="Add SKU"
        footer={null}
      >
        <SkuContant />
      </Modal>
    </div>
  );
};

export default ProductList;
