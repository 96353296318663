import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { axiosInstance } from "../../util/axiosInstance";
import AddProduct from "./Addproduct";
import ProductList from "./productlist";

const ProductMain = () => {
  const token = localStorage.getItem("Mr_Sasta");
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const apiUrl = `${baseURL}/getListOfProducts`;
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      if (data.responseCode === 200) {
        setProducts(data.data);
      } else if (data.responseCode === 400) {
        setError(data.message);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [token]);

  return (
    <Spin spinning={loading}>
      <div className="flex bg-white m-0 overflow-y-auto scrollbar-hide">
        <div className="w-[30%] overflow-y-auto scrollbar-hide">
          <AddProduct products={products} setProducts={setProducts} />
        </div>
        <div className="w-[2px] h-[750px] bg-gray-300 mx-4"></div>
        <div className="w-[70%]">
          <ProductList products={products} setProducts={setProducts} />
        </div>
      </div>
    </Spin>
  );
};

export default ProductMain;
