import React, { useState, useEffect } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { Switch, Pagination, message } from "antd";

const RolesList = ({ rolesData }) => {
  const [paginatedData, setPaginatedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const baseURL = process.env.REACT_APP_API_BASE_URL; // Ensure this is defined in your .env
  const token = localStorage.getItem("Mr_Sasta");
  const pageSize = 10;

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setPaginatedData(rolesData.slice(startIndex, endIndex));
  }, [currentPage, rolesData]);


  const toggleStatus = async (role) => {
    const roleId = role._id;
    setLoading(true);
    setError("");

    try {
      const response = await fetch(`${baseURL}/roleStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, 
        },
        body: JSON.stringify({ roleId }),
      });

      const result = await response.json();

      if (response.ok && result.responseCode === 200) {
        message.success(result.message);
        const updatedData = paginatedData.map((r) =>
          r._id === roleId ? { ...r, status: r.status === "active" ? "inactive" : "active" } : r
        );
        setPaginatedData(updatedData);
      } else if (response.status === 400) {
        throw new Error(result.message);
      } else {
        throw new Error(result.message);
      }
    } catch (err) {
      setError(err.message);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const editCustomer = (id) => {
    console.log(`Edit customer with ID: ${id}`);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const totalRoles = rolesData.length;

  return (
    <div className="p-4 bg-white">
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}
      <table className="min-w-full table-auto">
        <thead>
          <tr>
            <th className="border-b p-4 text-center">S.No</th>
            <th className="border-b p-4 text-center">Name</th>
            <th className="border-b p-4 text-center">Date Created</th>
            <th className="border-b p-4 text-center">Status</th>
            <th className="border-b p-4 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((role, index) => (
            <tr key={role._id} className="border-b">
              <td className="px-4 py-2">{(currentPage - 1) * pageSize + index + 1}</td>
              <td className="px-4 py-2 capitalize">{role?.roleName || "--"}</td>
              <td className="px-4 py-2">{role?.date || " - "}</td>
              <td className="px-4 py-2">
                <Switch
                  checked={role?.status === "active"}
                  onChange={() => toggleStatus(role)}
                  style={{
                    backgroundColor: role?.status === "active" ? "#009A00" : "#d9d9d9",
                  }}
                  loading={loading}
                />
              </td>
              <td className="px-4 py-2 text-center">
                <div className="flex justify-center">
                  <MdOutlineEdit
                    onClick={() => editCustomer(role._id)}
                    className="text-[#009A00] cursor-pointer"
                    size={18}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {paginatedData.length === 0 && (
        <div className="mt-3 text-center text-gray-500">
          <p>No data found</p>
        </div>
      )}

      <div className="mt-3 flex justify-end">
        {totalRoles > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={totalRoles}
            onChange={handleChangePage}
          />
        )}
      </div>
    </div>
  );
};

export default RolesList;
