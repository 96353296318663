import React, { useState, useEffect } from "react";
import { Spin} from "antd";
import RolesCard from "./RolesCard";
import RolesList from "./RolesList";

const RoleMain = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [rolesData, setRolesData] = useState([]);
  const [filter, setFilter] = useState("All"); 
  const [searchInput, setSearchInput] = useState("");
  const [activeCount, setActiveCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");

  const fetchRoles = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(`${baseURL}/listOfRoles`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      const result = await response.json();
  
      if (response.status === 200) {
        setRolesData(result.data);
        const activeRoles = result.data.filter((role) => role.status === "active");
        const inactiveRoles = result.data.filter((role) => role.status === "inactive");
  
        setTotalCount(result.data.length);
        setActiveCount(activeRoles.length);
        setInactiveCount(inactiveRoles.length);
      } else if (response.status === 400) {
        setError(result.message );
      } else {
        setError(result.message );
      }
    } catch (err) {
      setError(err.message );
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchRoles();
  }, []);
  const getFilteredRoles = () => {
    if (filter === "Active") {
      return rolesData.filter((role) => role.status === "active");
    } else if (filter === "Inactive") {
      return rolesData.filter((role) => role.status === "inactive");
    }
    return rolesData;
  };

  return (
    <Spin spinning={loading}>
    <div>
      <div>
        <RolesCard
          totalCount={totalCount}
          activeCount={activeCount}
          inactiveCount={inactiveCount}
          filter={filter}
          setFilter={setFilter}
        />
      </div>
      <div>
        <RolesList rolesData={getFilteredRoles()} />
      </div>
    </div>
    </Spin>
  );
};

export default RoleMain;
