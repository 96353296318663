import React, { useState, useEffect } from "react";
import { Spin, message } from "antd";
import RidersCard from "./RidersCard";
import RidersList from "./RidersList";

const RidersMain = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState("All");
  const [totalCount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [ridersData, setRidersData] = useState([]);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");

  const fetchRiders = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/getListOfRidersInfo`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        setRidersData(data.riders);

        const activeriders = data.riders.filter(
          (rider) => rider.status === "active"
        );
        const inactiveriders = data.riders.filter(
          (rider) => rider.status === "inactive"
        );

        setTotalCount(data.riders.length);
        setActiveCount(activeriders.length);
        setInactiveCount(inactiveriders.length);
      } else if (response.status === 400) {
        const data = await response.json();
        setError(data.message);
      }
    } catch (err) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRiders();
  }, [baseURL, token]);

  const filterRidersBySearchInput = (riders, searchInput) => {
    if (!searchInput) return riders;
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return riders.filter((rider) => {
      const lowerCaseAddress = String(rider.address).toLowerCase();
      return (
        rider.riderName.toLowerCase().includes(lowerCaseSearchInput) ||
        rider.email.toLowerCase().includes(lowerCaseSearchInput) ||
        String(rider.phone).includes(searchInput) ||
        lowerCaseAddress.includes(lowerCaseSearchInput)
      );
    });
  };

  const filteredRiders = filterRidersBySearchInput(
    ridersData.filter((rider) => {
      if (filter === "All") return true;
      if (filter === "Active") return rider.status === "active";
      if (filter === "Inactive") return rider.status === "inactive";
      return false;
    }),
    searchInput
  );

  return (
    <Spin spinning={loading}>
      <div>
        <div>
          <RidersCard
            riders={ridersData}
            filter={filter}
            setFilter={setFilter}
            activeCount={activeCount}
            inactiveCount={inactiveCount}
            totalCount={totalCount}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            fetchRiders={fetchRiders} 
            Rider={fetchRiders}
          />
        </div>
        <div>
          <RidersList riders={filteredRiders} />
        </div>
      </div>
    </Spin>
  );
};

export default RidersMain;
