import React from "react";
import MainApp from "../src/routes/index";
import { Layout } from "antd";
import "./App.css";
import { useSelector } from "react-redux";
import Sidebar from "./components/Sidebar/Sidebar";
import Header from "./components/Header/Header";

const { Content } = Layout;

const App = () => {
  const token = useSelector((state) => state.token.token);
  console.log(token);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout>
        <Header />
        <Layout>
          <Content style={{ margin: "16px", overflowY: "auto" }}>
            <div
              className='layout-content-section'
              style={{
                height: "calc(100vh - 64px - 53px)",
                overflowY: "auto",
              }}>
              <MainApp />
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;
