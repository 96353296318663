import axios from "axios";

// Fetch token from local storage or any other secure place
const token = localStorage.getItem("Mr_Sasta");
// const baseURL = process.env.REACT_APP_API_BASE_URL;

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Base URL for the API
  timeout: 10000, // Request timeout in milliseconds
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`, // Attach token for authorization
  },
});

export const noauthAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, 
  timeout: 10000, 
  headers: {
    "Content-Type": "application/json",
  },
});
