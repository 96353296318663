import React, { useState, useEffect } from "react";
import { Input, Select, Form, Button, message, Space, Upload } from "antd";
import { axiosInstance } from "../../util/axiosInstance";
import { PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const AddProduct = ({ setProducts }) => {
  const token = localStorage.getItem("Mr_Sasta");
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const categoriesEndpoint = "getAlladminCategories";
  const addProductEndpoint = `${baseURL}/addAdminsProduct`;

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [productImage, setProductImage] = useState(null);
  const [form] = Form.useForm();

  
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(categoriesEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        if (data.responseCode === 200) {
          setCategories(data.categories);
        } else {
          setError(data.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, [token, categoriesEndpoint]);

  const handleCategorySelect = (value) => {
    setSelectedCategory(value);
    console.log(value)
    console.log("Selected category:", value);
  };
  const handleImageUpload = (file) => {
    setProductImage(file);
    return false;
  };

  const onFinish = async (values) => {
    const productData = new FormData();
  
    // Explicitly convert to string when appending to FormData
    productData.append("productName", String(values.productName));
    productData.append("description", String(values.description));
    productData.append("categoryId", String(selectedCategory));
  
    // Append product image if available
    if (productImage) {
      productData.append("productImage", productImage);
    }
  
    // SKU object creation
    const sku = {
      productName: String(values.productName),
      unit: String(selectedUnit),
      quantityValue: Number(values.quantityValue),
      fairPrice: Number(values.fairPrice),
      instantPrice: Number(values.instantPrice),
      totalQuantity: Number(values.totalQuantity),
      maxLimitPurchase: Number(values.maxLimitPurchase),
      discount: {
        flat: Number(values.discountFlat ?? 0),
        percentage: Number(values.discountPercentage ?? 0),
      },
    };
  
    // Append SKU as a JSON string
    productData.append("skus", JSON.stringify([sku]));
  
    // Logging for debugging
    for (let pair of productData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
  
    try {
      const response = await axiosInstance.post(
        addProductEndpoint,
        productData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.data.responseCode === 200) {
        message.success(response.data.message);
        setProducts();
        form.resetFields();
        setProductImage(null);
      } else {
        message.error(response.data.message);
      }
    } catch (err) {
      message.error(err.message);
    }
  };
  
  return (
    <div className="max-w-md mx-auto p-2 bg-white overflow-y-auto scrollbar-hide">
      <Form
        form={form}
        name="addProduct"
        onFinish={onFinish}
        layout="vertical"
        className=""
      >
        <Form.Item
          name="category"
          label="Select Category"
          rules={[{ required: true, message: "Please select a category!" }]}
        >
          <Select
            placeholder="Select Category"
            className="w-full"
            value={selectedCategory}
            onChange={handleCategorySelect}
          >
            {categories
              ?.filter((item) => item.status === "active")
              .map((item) => (
                <Select.Option key={item._id} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="productName"
          label="Add product"
          rules={[
            { required: true, message: "Please Enter Name of the Product!" },
          ]}
        >
          <Input placeholder="Name of the Product" className="w-full" />
        </Form.Item>
        <div className="p-2 border-2 border-dashed rounded">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm font-medium">SKU's</span>
            <div className="inline-block w-20 px-2 py-1 border border-gray-300 rounded text-center">
              Qty
            </div>
          </div>
          <Form.Item name="totalQuantity" className="mb-2">
            <div className="flex space-x-2">
              <Space.Compact className="w-full">
                <Input placeholder="Quantity" className="w-full" />
                <Select
                  className="w-72"
                  value={selectedUnit}
                  onChange={(value) => setSelectedUnit(value)}
                >
                  <Select.Option value="kgs">Kgs</Select.Option>
                  <Select.Option value="grams">Grams</Select.Option>
                  <Select.Option value="packets">Packets</Select.Option>
                </Select>
              </Space.Compact>
            </div>
          </Form.Item>
          <Form.Item name="fairPrice" className="mb-2">
            <div className="flex space-x-2 ">
              <Input placeholder="Fair Price" suffix="Rs" className="w-full" />
            </div>
          </Form.Item>
          <Form.Item name="instantPrice" className="!mb-2">
            <div className="flex space-x-2 ">
              <Input
                placeholder="Instant Price"
                suffix="Rs"
                className="w-full"
              />
            </div>
          </Form.Item>
          <Form.Item name="discountFlat" className="mb-2">
            <Input
              placeholder="Flat Discount"
              suffix="Rs"
              className="w-full"
              type="number"
            />
          </Form.Item>

          <Form.Item name="discountPercentage" className="mb-2">
            <Input
              placeholder="Discount %"
              suffix="%"
              className="w-full"
              type="number"
            />
          </Form.Item>
          <div className="flex space-x-2 mb-2">
            <Input placeholder="000 Packets" className="w-1/2" />
            <Input placeholder="0 Kgs" className="w-1/2" />
          </div>

          <Form.Item name="maxLimitPurchase">
            <Input
              placeholder="Max Limit Purchase"
              suffix="kgs"
              className="w-full"
              type="number"
            />
          </Form.Item>
        </div>
        <div className="flex items-center text-sm text-gray-500 mb-2 justify-between mt-3">
          <span>You can add multiple SKU's</span>
          <Button icon={<PlusOutlined />} className="text-black border-black">
            Add SKU
          </Button>
        </div>

        <Form.Item name="description">
          <TextArea
            placeholder="Add description for Product"
            autoSize={{ minRows: 3, maxRows: 5 }}
            maxLength={175}
            showCount
          />
        </Form.Item>

        <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center flex-row justify-center mb-3">
          <div className="flex flex-col items-center">
            <img src="/Assests/Images/image-gallery.png" />
          </div>
          <div className="flex flex-col items-center">
            <p className="mt-2 text-sm">
              Drop your files here or{" "}
              <span className="!text-[#009A00]">browse</span>
            </p>
            <p className="text-xs text-gray-500">Maximum size: 50MB</p>
          </div>
        </div>

        <Form.Item>
          <Button
            htmlType="submit"
            className="w-full !bg-[#009A00] text-white hover:bg-[#009A00]  font-medium"
          >
            Add Product
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddProduct;
