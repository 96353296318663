import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import { RxDashboard } from "react-icons/rx";
import {
  MdCategory,
  MdOutlineInventory2,
  MdPayment,
  MdHelpOutline,
  MdOutlineDashboardCustomize,
  MdOutlineSell ,
} from "react-icons/md";
import { SlControlEnd } from "react-icons/sl";
import { CiStar } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { RiCustomerService2Fill, RiEBikeLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { TbBoxMultiple } from "react-icons/tb";
import { BsBox2, BsPeople, BsWindowSidebar } from "react-icons/bs";
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { updatingSelectedPujas } from "../../redux/action";
import { useSelector, useDispatch } from "react-redux";
const { Sider } = Layout;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const selectedKeys = path.substr(1);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const profileDetails = useSelector((state) => state.userProfile);
  console.log(profileDetails);
  const handleMenuItemSelect = (menuItemKey) => {
    console.log(menuItemKey);
    dispatch(updatingSelectedPujas(menuItemKey));
  };

  const selectedPuja = useSelector((state) => state.selectedPujas);
  console.log(selectedPuja);

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className={`h-screen fixed top-0 left-0 shadow-lg !bg-white transition-all duration-300 ${
        collapsed ? "!w-20" : "!w-72"
      }`}
    >
      {/* Logo and Toggle */}
      <div className="flex items-center justify-between p-4 bg-white">
        {!collapsed && (
          <div
            className={`text-[#009A00] transition-all duration-300 fill-current`}
          >
            <img
              src="/Assests/Images/Mr_Sasta-Logo.png"
              alt="Logo"
              className="transition-all duration-300"
            />
          </div>
        )}
        <div
          className={`${
            collapsed ? "flex justify-center items-center w-full" : ""
          } !text-[#009A00] cursor-pointer text-xl`}
          onClick={toggleCollapsed}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
      </div>

      {/* Menu Items */}
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[selectedKeys]}
        className="text-base !bg-white !text-black"
      >
        <Menu.Item
          key="home"
          icon={<RxDashboard />}
          className="!bg-white !text-black"
          onClick={() => handleMenuItemSelect("Dashboard")}
        >
          <Link to="/home">Dashboard</Link>
        </Menu.Item>
        <Menu.Item
          key="Inventory"
          icon={<MdOutlineInventory2 />}
          onClick={() => handleMenuItemSelect("Inventory")}
        >
          <Link to="/Inventory">Inventory</Link>
        </Menu.Item>
        <Menu.Item key="Categories" icon={<TbBoxMultiple />} onClick={() => handleMenuItemSelect("Categories")}>
          <Link to="/categories">Categories</Link>
        </Menu.Item>
        <Menu.Item key="Products" icon={<BsWindowSidebar />} onClick={() => handleMenuItemSelect("Products")}>
          <Link to="/Products">Products</Link>
        </Menu.Item>
        <Menu.Item key="Orders" icon={<BsBox2 />} onClick={() => handleMenuItemSelect("Orders")}>
          <Link to="/OrderManagement">Orders</Link>
        </Menu.Item>
        <Menu.Item key="Customers" icon={<MdOutlineDashboardCustomize />} onClick={() => handleMenuItemSelect("Customers")}>
          <Link to="/customer">Customers</Link>
        </Menu.Item>
        <Menu.Item key="Reviews" icon={<CiStar />} onClick={() => handleMenuItemSelect("Reviews")}>
          <Link to="/Reviews">Reviews</Link>
        </Menu.Item>
        <Menu.Item key="Payment" icon={<MdPayment />} onClick={() => handleMenuItemSelect("Payment")}>
          <Link to="/Payment">Payment</Link>
        </Menu.Item>
        <Menu.Item key="Roles" icon={<SlControlEnd />} onClick={() => handleMenuItemSelect("Roles")}>
          <Link to="/Roles">Roles</Link>
        </Menu.Item>
        <Menu.Item key="Employes" icon={<BsPeople />} onClick={() => handleMenuItemSelect("Employes")}>
          <Link to="/Employee">Employes</Link>
        </Menu.Item>
        <Menu.Item key="Sellers" icon={<MdOutlineSell  />} onClick={() => handleMenuItemSelect("Sellers")}>
          <Link to="/Sellers">Sellers</Link>
        </Menu.Item>
        <Menu.Item key="Riders" icon={<RiEBikeLine />} onClick={() => handleMenuItemSelect("Riders")}>
          <Link to="/Riders">Riders</Link>
        </Menu.Item>
        <Menu.Item key="Help Center" icon={<RiCustomerService2Fill />} onClick={() => handleMenuItemSelect("Help Center")}>
          <Link to="/Help-Center">Help Center</Link>
        </Menu.Item>
        {/* <Menu.Item key="Profile" icon={<CgProfile />}>
          <Link to="/Profile">Profile</Link>
        </Menu.Item> */}
        <Menu.Item key="Settings" icon={<IoSettingsOutline />} onClick={() => handleMenuItemSelect("Settings")}>
          <Link to="/Settings">Settings</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
