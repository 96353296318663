import React, { useState } from "react";
import { Switch } from "antd";

const SkuContant = () => {
  const [openInputs, setOpenInputs] = useState(Array(3).fill(false));

  const dummyData = [
    {
      _id: 1,
      productStream: "Pouches",
      piecesInaPack: 10,
      singlePiecePrice: 50,
      packets: 20,
      status: "active",
    },
    {
      _id: 2,
      productStream: "Qty",
      quantityValue: 5,
      quantityType: "kg",
      packets: 10,
      status: "inactive",
    },
  ];

  return (
    <div className="SKU-modal-container bg-white p-6">
      <div className="SKU-modal-header flex justify-between items-center mb-4">
        <h6 className="text-lg font-semibold">SKU's</h6>
        <div className="flex items-center bg-[#009A00] text-white px-3 py-1 rounded cursor-pointer">
          <p className="mr-2">+</p>
          <p>Add SKU</p>
        </div>
      </div>
      <div className="SKU-modal-table">
        <table className="w-full text-left border-collapse">
          <thead>
            <tr className="border-b-2 border-gray-200">
              <th className="py-2 text-center">S.No</th>
              <th className="py-2 text-center">Quantity</th>
              <th className="py-2 text-center">Price</th>
              <th className="py-2 text-center">Packets</th>
              <th className="py-2 text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {dummyData.map((item, index) => (
              <tr key={item._id} className="border-b">
                <td className="py-2 text-center">{index + 1}</td>
                {item?.productStream === "Qty" ? (
                  <td className="py-2 text-center">{`${item?.quantityValue} ${item?.quantityType}`}</td>
                ) : (
                  <td className="py-2 text-center">{`${item?.piecesInaPack} pcs`}</td>
                )}
                {item?.productStream === "Pouches" ? (
                  <td className="py-2 text-center">
                    <span>{item?.singlePiecePrice}</span>
                  </td>
                ) : (
                  <td className="py-2 text-center">-</td>
                )}
                <td className="py-2 text-center">
                  <span>{item.packets}</span>
                </td>
                <td className="py-2 text-center">
                  <Switch
                    checked={item.status === "active"}
                    style={{
                      backgroundColor:
                        item?.status === "active" ? "#009A00" : "#d9d9d9",
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SkuContant;
