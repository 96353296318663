import React, { useState, useEffect } from "react";
import { Input, Button, Modal } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import AddRider from "./AddRider";

const RidersCard = ({
  riders,
  filter,
  setFilter,
  activeCount,
  inactiveCount,
  totalCount,
  searchInput,
  setSearchInput,
  Rider,
}) => {
  const [showAddRiderModal, setShowAddRiderModal] = useState(false);
  const handleFilterClick = (filterType) => {
    setFilter(filterType);
  };
  const close = () => {
    setShowAddRiderModal(false);
  };

  return (
    <div className="bg-white p-4 flex justify-between items-center border-b">
      <div className="flex items-center space-x-4">
        <div className="relative">
          <Input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            suffix={<SearchOutlined className="text-gray-400" />}
            placeholder="Search..."
            className="pl-4 pr-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#009A00]"
          />
        </div>
        <div className="flex space-x-2">
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "All"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-[#6B7280] border-[#E5E7EB]"
            }`}
            onClick={() => handleFilterClick("All")}
          >
            ALL ({totalCount})
          </button>
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "Active"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-[#6B7280] border-[#E5E7EB]"
            }`}
            onClick={() => handleFilterClick("Active")}
          >
            ACTIVE ({activeCount})
          </button>
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "Inactive"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-[#6B7280] border-[#E5E7EB]"
            }`}
            onClick={() => handleFilterClick("Inactive")}
          >
            INACTIVE ({inactiveCount})
          </button>
        </div>
      </div>
      <div className="flex gap-3">
        <div className="flex space-x-4 items-center">
          <button className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100">
            <MdOutlinePictureAsPdf size={15} className="mr-1" />
            Export to Excel
          </button>
        </div>
        <div className="flex justify-end">
          <Button
            className="bg-[#009A00] text-white flex items-center px-6 py-5 rounded hover:bg-[#008000] transition-all"
            onClick={() => setShowAddRiderModal(true)}
          >
            <PlusOutlined className="mr-2" />
            Add Rider
          </Button>
        </div>
      </div>
      <Modal
        visible={showAddRiderModal}
        onCancel={() => setShowAddRiderModal(false)}
        centered
        fixed
        footer={null}
        className="custom-modal h-[80%] overflow-auto rounded"
      >
        <AddRider  riders={riders}  close={close} Rider={Rider}/>
      </Modal>
    </div>
  );
};

export default RidersCard;
