import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import DashboardCard from "./DashboardCard";
import Dashboardchart from "./Dashboardchart";
import DashboardList from "./DashboardList";

const DashboardMain = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  return (
    <Spin spinning={loading}>
      <div>
        <div>
          <DashboardCard />
        </div>
        <div>
          <Dashboardchart />
        </div>
        <div>
          <DashboardList />
        </div>
      </div>
    </Spin>
  );
};

export default DashboardMain;
