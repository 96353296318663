import React, { useState , useEffect } from "react";
import { Avatar, Switch, Pagination, message } from "antd";
import { MdOutlineEdit } from "react-icons/md";

const RidersList = ({ riders }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [riderData, setRiderData] = useState(riders);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");
  const pageSize = 10;

  useEffect(() => {
    setRiderData(riders);
  }, [riders]); 
  
  const toggleStatus = async (rider) => {
    const newStatus = rider.status === "active" ? "inactive" : "active";

    try {
      const response = await fetch(`${baseURL}/toggleRider`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          riderId: rider._id,
          status: newStatus,
        }),
      });
      const data = await response.json();
      if (response.status === 200) {
        const updatedRiders = riderData.map((r) =>
          r._id === rider._id ? { ...r, status: newStatus } : r
        );
        setRiderData(updatedRiders);
        message.success(data.message);
      } else if (response.status === 400) {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = riderData.slice(startIndex, endIndex);

  return (
    <div className="p-4 bg-white">
      <table className="min-w-full">
        <thead className="bg-white">
          <tr className="border-t">
            <th className="border-b p-4 text-center">S.No</th>
            <th className="border-b p-4 text-left">Name</th>
            <th className="border-b p-4 text-left">E-mail</th>
            <th className="border-b p-4 text-center">Phone</th>
            <th className="border-b p-4 text-center">Driving License</th>
            <th className="border-b p-4 text-center">Aadhaar Number</th>
            <th className="border-b p-4 text-left">Address</th>
            <th className="border-b p-4 text-center">Status</th>
            <th className="border-b p-4 text-center">Created At</th>
            <th className="border-b p-4 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((rider, index) => (
            <tr key={rider._id}>
              <td className="border-b p-4 text-center">
                {startIndex + index + 1}
              </td>
              <td className="border-b p-4 flex items-center gap-3">
                <Avatar
                  src={rider.profilePic || ""}
                  size={35}
                  shape="circle"
                  className="mr-2 bg-gray-200 text-black capitalize"
                >
                  {rider.riderName ? rider.riderName[0] : ""}
                </Avatar>
                {rider.riderName || "--"}
              </td>
              <td className="border-b p-4">{rider.email || "--"}</td>
              <td className="border-b p-4 text-center">{rider.phone || "--"}</td>
              <td className="border-b p-4 text-center">{rider.drivingLicense || "--"}</td>
              <td className="border-b p-4 text-center">{rider.aadhaarNumber || "--"}</td>
              <td className="border-b p-4">{rider.address || "--"}</td>
              <td className="border-b p-4 text-center">
                <Switch
                  checked={rider.status === "active"}
                  onChange={() => toggleStatus(rider)}
                  className="riderlistBody_antSwitch"
                  style={{
                    backgroundColor: rider.status === "active" ? "#009A00" : "#d9d9d9",
                  }}
                />
              </td>
              <td className="border-b p-4 text-center">{rider.createdAt || "--"}</td>
              <td className="border-b p-4 text-center">
                <div className="flex justify-center">
                  <MdOutlineEdit
                    onClick={() => console.log(`Edit rider with id: ${rider._id}`)}
                    className="text-[#009A00] cursor-pointer"
                    size={18}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="Pagination-container mt-3 justify-end flex">
        {riderData.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={riderData.length}
            onChange={handleChangePage}
          />
        )}
      </div>
    </div>
  );
};

export default RidersList;
