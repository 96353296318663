import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import { axiosInstance } from "../../util/axiosInstance";
import { handleProfileImage } from "../../containers/fileupload/fileupload";
const { TextArea } = Input;

const AddCategory = ({
  fetchCategories,
  updateCategory,
  setUpdateCategory,
  setLoading,
  setError,
}) => {
  const [form] = Form.useForm();
  const [categoryImage, setCategoryImage] = useState("");
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const addEndpoint = "craeteAdminCatagories";
  const editEndpoint = "editAdminCatagories";
  const token = localStorage.getItem("Mr_Sasta");

  useEffect(() => {
    if (updateCategory) {
      form.setFieldsValue({
        name: updateCategory.name,
        description: updateCategory.description,
      });
      setCategoryImage(updateCategory.image || "");
    }
  }, [updateCategory, form]);

  const onFinish = async (values) => {
    const payload = {
      name: values.name,
      description: values.description,
      banner: "Vegies.jpg",
      image: categoryImage,
    };
    try {
      const endpoint = updateCategory ? editEndpoint : addEndpoint;
      const method = updateCategory ? "PUT" : "POST";
  
      if (updateCategory) {
        payload.categoryId = updateCategory._id;
      }
  
      const response = await fetch(`${baseURL}/${endpoint}`, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
  
      if (response.status === 200) {
        message.success(data.message);
        form.resetFields();
        setCategoryImage("");
        fetchCategories();
        setUpdateCategory(null);
      } else if (response.status === 400) {
        message.error(data.message);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error);
    }
  };
  
  const handleAadharImage = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg"];

    if (!allowedTypes.includes(file.type)) {
      message.error("Only PNG and JPG images are allowed.");
      // event.target.value = null;
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      message.error("Image is greater than 5MB. The maximum limit is 5MB.");
      // event.target.value = null;
      return;
    }
    setLoading(true);
    handleProfileImage(event)
      .then((imageUrl) => {
        console.log(imageUrl);
        setCategoryImage(imageUrl);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        message.error(error);
        setLoading(false);
      });
  };
  return (
    <div className="p-2 bg-white">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="space-y-4"
      >
        <Form.Item
          name="name"
          label={updateCategory ? "Edit Category" : "Add Category"}
          rules={[{ required: true, message: "Please enter category name" }]}
        >
          <Input
            placeholder="Name of the Category"
            className="border-gray-300 rounded-md w-full"
          />
        </Form.Item>

        <Form.Item name="description" label="Description" className="!mb-2">
          <TextArea
            rows={4}
            placeholder="Add description for category"
            maxLength={350}
            showCount
            className="border-gray-300 rounded-md w-full"
          />
        </Form.Item>

        <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4 !mt-[29px]">
          <div className="flex justify-center items-center">
            {/* Display selected image if available */}
            {categoryImage ? (
              <img
                src={categoryImage} 
                alt="Category Preview"
                className="w-20 h-18 mb-4"
              />
            ) : (
              <img
                src="/Assests/Images/image-gallery.png"
                alt="Default Category"
                className="w-20 h-18"
              />
            )}
          </div>

          <div className="flex flex-col items-center">
            <p>
              Drop your files here or{" "}
              <label
                htmlFor="fileInput"
                className="text-[#009A00] cursor-pointer"
              >
                {categoryImage ? "Change Image" : "browse"}
              </label>
            </p>
            <p className="text-sm text-gray-500">Maximum size: 5MB</p>
            <input
              type="file"
              id="fileInput"
              onChange={handleAadharImage}
              className="!hidden"
            />
          </div>
        </div>
        <Button
          type="primary"
          htmlType="submit"
          className="w-full !bg-[#009A00] !hover:bg-[#009A00] text-white py-2 rounded-md font-medium"
        >
          {updateCategory ? "Update Category" : "Add Category"}
        </Button>
      </Form>
    </div>
  );
};

export default AddCategory;
