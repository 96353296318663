import React from "react";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";

const Customercard = ({
  customers,
  totalCount,
  activeCount,
  inactiveCount,
  setFilter,
  filter,
  searchInput,
  setSearchInput,
}) => {
  const onExportLocal = () => {
    const formattedData = customers.map((item, index) => ({
      "S.No": index + 1,
      "Name of the Customer": item.name,
      "E-mail": item.email,
      "Contact": item.phone,
      "Address": item.address,
      "Status": item.status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "customers");

    const wscols = [
      { wch: 5 }, // S.No
      { wch: 35 }, // Name of the Customer
      { wch: 35 }, // E-mail
      { wch: 25 }, // Contact
      { wch: 30 }, // Address
      { wch: 10 }, // Status
    ];
    worksheet["!cols"] = wscols;

    XLSX.writeFile(workbook, "customers.xlsx");
  };

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <div className="bg-white p-4 flex justify-between items-center border-b">
      <div className="flex items-center space-x-4">
        <div className="relative">
          <Input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            suffix={<SearchOutlined className="text-gray-400" />}
            placeholder="Search..."
            className="pl-4 pr-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#009A00]"
          />
        </div>
        <div className="flex space-x-2">
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "All"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-[#6B7280] border-[#E5E7EB]"
            }`}
            onClick={() => handleFilterClick("All")}
          >
            ALL ({totalCount})
          </button>
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "Active"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-[#6B7280] border-[#E5E7EB]"
            }`}
            onClick={() => handleFilterClick("Active")}
          >
            ACTIVE ({activeCount})
          </button>
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "Inactive"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-[#6B7280] border-[#E5E7EB]"
            }`}
            onClick={() => handleFilterClick("Inactive")}
          >
            INACTIVE ({inactiveCount})
          </button>
        </div>
      </div>
      <div className="flex space-x-4 items-center">
        <button
          className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100"
          onClick={onExportLocal}
        >
          <MdOutlinePictureAsPdf size={15} className="mr-1" />
          Export to Excel
        </button>
      </div>
    </div>
  );
};

export default Customercard;
