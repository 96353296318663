import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { MdOutlinePictureAsPdf } from "react-icons/md";

const OrderManagementBody = ({
  filter,
  setFilter,
  searchInput,
  setSearchInput,
  totalCount,
  dispatchedCount,
  deliveredCount,
  returnsCount,
}) => {

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };

  const onExportLocal = () => {
    // Export functionality can go here
  };

  return (
    <div className="py-5 px-2">
      <div className="flex justify-between border-b border-gray-300 py-5">
        <div className="flex items-center gap-2 w-4/5">
          <div className="flex items-center mr-2 w-1/3">
            <Input
              type="search"
              placeholder="Search"
              className="py-1 px-2 border border-gray-300 rounded w-full"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              suffix={<SearchOutlined className="text-gray-500" />}
            />
          </div>
          <div className="flex space-x-2 w-full ml-3">
            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "All" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("All")}
            >
              ALL ({totalCount})
            </button>
            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "inprogress" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("Inprogress")}
            >
              Packed ({dispatchedCount})
            </button>
            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "Delivered" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("Delivered")}
            >
              Delivered ({deliveredCount})
            </button>
            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "Returns" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("Returns")}
            >
              Returns ({returnsCount})
            </button>
          </div>
        </div>
        <div className="flex items-center justify-end w-1/5 gap-4">
          <button
            className="bg-white text-gray-500 border border-gray-300 py-2 px-5 flex items-center gap-1 text-sm rounded"
            onClick={onExportLocal}
          >
            <MdOutlinePictureAsPdf size={15} className="text-gray-500" />
            Export to CSV
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderManagementBody;
