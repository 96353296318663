import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import OrderManagementCards from "./orderManagementcard";
import OrderManagementBody from "./orderManagementBody";
import OrderMListBody from "./ordermanagementlist";

const OrderManagementMain = () => {
 const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [ordersList, setOrdersList] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [counts, setCounts] = useState({
    totalCount: 0,
    dispatchedCount: 0,
    deliveredCount: 0,
    returnsCount: 0,
    damagedCount: 0
  });

  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await fetch(`${baseURL}/listOfOrderManagement`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            startDate: "",
            endDate: "",
          }),
        });
  
        if (!response.ok) {
          if (response.status === 400) {
            const errorData = await response.json(); 
            throw new Error(errorData.message );
          } else {
            throw new Error();
          }
        }
        const data = await response.json();
        if (data.responseCode === 200) {
          setOrdersList(data.data); 
        } else if (data.responseCode === 400) {
          setError(data.message); 
        } else {
          setError(data.message );
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchOrders();
  }, [baseURL, token]);
  
  useEffect(() => {
    const filterAndSearchOrders = () => {
      const lowerCaseSearchInput = searchInput.toLowerCase();

      const filtered = ordersList.filter((order) => {
        const matchesFilter = 
          filter === "All" ||
          (filter === "Dispatched" && order.orderStatus === "Packing Completed") ||
          (filter === "Delivered" && order.orderStatus === "Delivered") ||
          (filter === "Returns" && order.orderStatus === "Return");

        const matchesSearch = 
          order.customerName?.toLowerCase()?.includes(lowerCaseSearchInput) ||
          order.paymentMethod?.toLowerCase()?.includes(lowerCaseSearchInput) ||
          order.bookingId?.toLowerCase()?.includes(lowerCaseSearchInput);

        return matchesFilter && matchesSearch;
      });

      const totalCount = filtered.length;
      const dispatchedCount = filtered.filter((order) => order.orderStatus === "Packing Completed").length;
      const deliveredCount = filtered.filter((order) => order.orderStatus === "Delivered").length;
      const returnsCount = filtered.filter((order) => order.orderStatus === "Return").length;
      const damagedCount = filtered.filter((order) => order.orderStatus === "Damaged").length;

      setFilteredOrders(filtered);
      setCounts({
        totalCount,
        dispatchedCount,
        deliveredCount,
        returnsCount,
        damagedCount,
      });
    };

    filterAndSearchOrders();
  }, [filter, searchInput, ordersList]);

  return (
    <Spin spinning={loading}>
    <div>
      <div>
        <OrderManagementCards />
      </div>
      <div>
        <OrderManagementBody
         setSearchInput={setSearchInput}
         filter={filter}
         setFilter={setFilter}
         totalCount={counts.totalCount}
         dispatchedCount={counts.dispatchedCount}
         deliveredCount={counts.deliveredCount}
         returnsCount={counts.returnsCount}
        />
      </div>
      <div>
        <OrderMListBody ordersList={ordersList} />
      </div>
    </div></Spin>
  );
};

export default OrderManagementMain;
