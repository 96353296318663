import { Routes, Route } from "react-router-dom";

import CategoryMain from "./Categories";
import CustomerMain from "./Customers";
import ProductMain from "./Products";
import EmployeeMain from "./Employess";
import OrderManagementMain from "./OrderManegagement";
import RoleMain from "./Roles";
import DashboardMain from "./Dashboard";
import RidersMain from "./Riders";


const MainApp = () => {
  return (
    <div className='App'>
      <Routes>
        <Route path='/home' element={<DashboardMain />} />
        <Route path='/categories' element={<CategoryMain />} />
        <Route path="/customer" element={<CustomerMain />} />
        <Route path='/Products' element={<ProductMain />} />
        <Route path='/Employee' element={<EmployeeMain />} />
        <Route path='/OrderManagement' element={<OrderManagementMain />} />
        <Route path="/Roles" element={<RoleMain />} />
        <Route path="/Riders" element={<RidersMain />} />
      </Routes>
    </div>
  );
};

export default MainApp;
